import React, { useRef, useState } from "react";
import axios from "axios";
import { isEmpty } from "../utils/helpers";

import {
  PasscodeInputContainer,
  PasscodeInputHeader,
  PasscodeInputSubHeader,
  InputAndButtonContainer,
  StyledButton,
  StyledPasscodeInput,
  ValidationMessage
} from "./styles";

const Found = ({ email, isCompanyPortal }) => {
  const BUTTON_TEXT = "continue";
  const HEADER_TEXT = "Check your email.";
  const SUBHEADER_TEXT = "You should be receiving a passcode shortly. Please enter it below.";
  const SUBMIT_PATH = isCompanyPortal ?
    "/company_portal/lookup/passcode_submit" :
    "/resident_portal/lookup/passcode_submit";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [validationMessage, setValidationMessage] = useState(null);

  const handleKeyDown = (event) => {
    if (isSubmitting) return;
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit(event);
    }
  };

  const handleSubmit = async () => {
    const passcode = inputValue;

    try {
      setIsSubmitting(true);
      const res = await axios.get(SUBMIT_PATH, { params: { passcode: passcode, email: email } });
      if (res.data.success) {
        window.location = res.data.redirect_url;
      } else {
        setValidationMessage(res.data.message);
        setTimeout(() => {
          setValidationMessage(null);
        }, 5000);
      }
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  };

  const isSubmitDisabled = isEmpty(inputValue) || isSubmitting;

  return (
    <>
      <PasscodeInputContainer>
        <PasscodeInputHeader>{HEADER_TEXT}</PasscodeInputHeader>
        <PasscodeInputSubHeader>{SUBHEADER_TEXT}</PasscodeInputSubHeader>
        <InputAndButtonContainer>
          <StyledPasscodeInput
            aria-label="passcode input"
            autoFocus
            data-testid="passcode-input"
            disabled={isSubmitting}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            placeholder='passcode'
            type='password'
          />
          <StyledButton
            aria-label="continue button"
            data-testid="passcode-continue-btn"
            disabled={isSubmitDisabled}
            onClick={() => handleSubmit()}
          >
            {BUTTON_TEXT}
          </StyledButton>
        </InputAndButtonContainer>
      </PasscodeInputContainer>
      {validationMessage && <ValidationMessage data-testid={"validation-message"}>{validationMessage}</ValidationMessage>}
    </>
  );
}

export default Found;
