import React, { useState } from "react";

import EmailInput from "./EmailInput";
import PasswordInput from "./PasswordInput";
import { UtilityButton, InputAndButtonContainer, LoginContainer } from "./styles";
import { CompanyLogo } from "../shared/styles";
import useCompanyImage from '../../hooks/useCompanyImage'

const Login = ({ recaptchaSiteKey }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginState, setLoginState] = useState("email");
  const [email, setEmail] = useState("");
  const { loading, error, image } = useCompanyImage('stern_logo')

  const handleEmailSubmit = (email, loginType) => {
    setEmail(email);
    setLoginState(loginType);
  }

  const goBackToEmailInput = () => {
    setIsSubmitting(true);
    window.location = "/admin/login";
  };

  const redirectForgotPassword = () => {
    setIsSubmitting(true);
    window.location = "/admin/forgot_password";
  };

  const InputType = () => {
    if (loginState === "email") {
      return (
        <EmailInput
          recaptchaSiteKey={recaptchaSiteKey}
          handleEmailSubmit={handleEmailSubmit}
        />
      );
    } else {
      return (
        <PasswordInput email={email} loginState={loginState} />
      );
    }
  };

  return (
    <LoginContainer>
      <CompanyLogo data-testid={'stern_logo-company-logo'} src={image} alt='logo' />
      <InputType />
      {loginState !== "email" && (
        <InputAndButtonContainer>
          <UtilityButton
            data-testid="back-btn"
            disabled={isSubmitting}
            onClick={() => goBackToEmailInput()}
          >
            Back
          </UtilityButton>
          {loginState === "password" && (
            <UtilityButton
              data-testid="forgot-password-btn"
              disabled={isSubmitting}
              onClick={() => redirectForgotPassword()}
            >
              Forgot password?
            </UtilityButton>
          )}
        </InputAndButtonContainer>
      )}
    </LoginContainer>
  );
}

export default Login;
