import React from "react";

import { StandardText } from "../shared/styles";

const Instructions = ({ residentPortalLandingPageVerbiage }) => {

  const renderInstructions = () => {
    return residentPortalLandingPageVerbiage.map((instruction) => {
      return <div key={instruction} dangerouslySetInnerHTML={{__html: instruction}}></div>
    });
  }

  return (
    <StandardText>
      {renderInstructions()}
    </StandardText>
  );
}

export default Instructions;