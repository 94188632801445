import React, { createContext } from "react";

const CompanyContext = createContext();

export const CompanyProvider = (props) => {
  const {
    children,
    companyGroupSlug,
    companyGroupName,
    residentPortalLogoSlug,
  } = props;

  return (
    <CompanyContext.Provider
      value={{
        companyGroupSlug,
        companyGroupName,
        residentPortalLogoSlug,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyContext;
