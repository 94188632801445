import React from "react";

import EmailInput from "./EmailInput";
import Instructions from "./Instructions";
import PortalWrapper from "../PortalWrapper";
import { CompanyProvider } from "../../contexts/CompanyContext";

const ResidentIntro = ({
  companyGroupSlug,
  companyGroupName,
  isCompanyPortal = false,
  isResidentLookup = false,
  residentPortalLandingPageVerbiage,
  residentPortalLogoSlug,
  recaptchaSiteKey
}) => {
  return (
    <CompanyProvider
      companyGroupSlug={companyGroupSlug}
      companyGroupName={companyGroupName}
      residentPortalLogoSlug={residentPortalLogoSlug}
    >
      <PortalWrapper>
        {!isCompanyPortal && (
          <Instructions residentPortalLandingPageVerbiage={residentPortalLandingPageVerbiage} />
        )}
        <EmailInput
          isCompanyPortal={isCompanyPortal}
          isResidentLookup={isResidentLookup}
          recaptchaSiteKey={recaptchaSiteKey}
        />
      </PortalWrapper>
    </CompanyProvider>
  );
}

export default ResidentIntro;