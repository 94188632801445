import React, { useState, useEffect } from "react";

import { redirectTo } from "../utils/helpers";
import { StyledBackButton } from "./styles";
import { StandardText } from "../shared/styles";

const NotFound = ({companyGroupSlug, isCompanyPortal, isResidentLookup}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const BACK_PATH = isCompanyPortal ?
    (isResidentLookup ? "/company_portal/lookup/resident_search" : `/company_portal/${companyGroupSlug}`) :
    `/resident_portal/${companyGroupSlug}`;
  const BUTTON_TEXT = "back";
  const HEADER_TEXT = isCompanyPortal ?
    (isResidentLookup ? "A resident with that email address could not be found." : "Your email could not be verified. Please use the email address associated with your company user account.") :
    "Your email could not be verified. Please use the same email address that was submitted with your application and Lease or Housing Agreement.";

  const handleBackClick = () => {
    try {
      setIsSubmitting(true);
      redirectTo(BACK_PATH);
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <StandardText>{HEADER_TEXT}</StandardText>
      <StyledBackButton
        data-testid="back-btn"
        disabled={isSubmitting}
        onClick={() => handleBackClick()}
        >
        {BUTTON_TEXT}
      </StyledBackButton>
    </>
  );
};

export default NotFound;