export const FILE_TYPE_INFO_MAPPINGS = {
  jpeg: "image",
  jpg: "image",
  heic: "image",
  heif: "image",
  png: "image",
  pdf: "PDF",
};

export const FILE_ERROR_MESSAGES = {
  fileSize: "File must be under 20MB in size",
  unsupportedMimeType: "File must be .png, .jpg, .heic, .heif, or .pdf",
};

export const KILOBYTES_DIVIDER = 1024;

export const MAX_FILE_SIZE = 20 * KILOBYTES_DIVIDER * KILOBYTES_DIVIDER; // 52428800

export const SUPPORTED_MIME_TYPES = [
  "image/png",
  "image/jpeg",
  "image/heic",
  "image/heif",
  "application/pdf",
];