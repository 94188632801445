import { FILE_TYPE_INFO_MAPPINGS, KILOBYTES_DIVIDER } from "./constants";

export const bytesToSize = (bytes) => {
  const prefixes = ["B", "KB", "MB", "GB", "TB"];

  if (bytes === 0) return "0 B";

  const prefixIndex = parseInt(Math.floor(Math.log(bytes) / Math.log(KILOBYTES_DIVIDER)), 10);

  return `${Math.round((bytes / KILOBYTES_DIVIDER ** prefixIndex) * 100) / 100} ${
    prefixes[prefixIndex]
  }`;
};

const fileTypeInfo = (extension) => {
  const type = FILE_TYPE_INFO_MAPPINGS[extension];
  return type || "File";
};

export const fileMetadata = (file) => {
  const filenameParts = file.name.split(".");
  const fileExtension = filenameParts.pop().toLowerCase();

  return {
    name: file.name,
    nameWithoutExtension: filenameParts.join("."),
    typeInfo: fileTypeInfo(fileExtension),
    size: file.size,
    extension: fileExtension,
  };
};

export const isObj = (val) => {
  val !== null && typeof val !== "undefined" && !Array.isArray(val) && typeof val === "object";
};

export const isEmpty = (val) => {
  if (typeof val === "undefined") {
    return (_val) => (typeof _val === "undefined" ? true : isEmpty(_val));
  }

  if (val instanceof Map || val instanceof Set) {
    return val.size < 1;
  }
  if (Array.isArray(val)) {
    return !val.length || val.length === 0;
  }
  if (isObj(val)) {
    return !Object.keys(val).length || Object.keys.length === 0;
  }
  if (val === null) {
    return true;
  }
  if (val === "") {
    return true;
  }

  return false;
};

export const redirectTo = (url) => {
  window.location.href = url;
};