export default function multiSearch() {
  return {
    query: "",
    isLoading: false,
    results: null,
    resetSearch() {
      this.query = "";
    },
    fetchSearch() {
      this.isLoading = true;
      fetch(`/search?q=${this.query}`)
        .then(response => response.text())
        .then(data => {
          this.isLoading = false;
          this.results = data;
        });
    }
  };
}
