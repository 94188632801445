import styled from "@emotion/styled";
import { devices } from '../../stylesheets/devices.js';
import { colors } from '../../stylesheets/colors.js';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  width: 45rem;

  @media ${devices.mobileS} {
    width: 100%;
  }

  @media ${devices.tablet} {
    max-width: 75%;
  }
  
  @media ${devices.laptopL} {
    max-width: 40%;
  }
`;

export const InputHeader = styled.h1`
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;

  @media ${devices.mobileS} {
    font-size: 1.2rem;
  }

  @media ${devices.tablet} {
    font-size: 1.4rem;
  }
`;

export const InputSubHeader = styled.h2`
  font-size: 1rem;  
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const InputAndButtonContainer = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  margin-bottom: 1rem;

  @media ${devices.mobileS} {
    flex-direction: column;
  }
  
  @media ${devices.laptop} {
    flex-direction: row;
  }
`;

export const LoginContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4rem;
  height: 100vh;
  width: 100vw;
`;

export const StyledButton = styled.button`
  background-color: ${colors.almostBlack};
  border-radius: 6px;
  color: #f7f7f7;
  font-weight: 500;
  padding: 0.5rem 1rem;
  text-transform: uppercase;

  @media ${devices.mobileS} {
    padding: 1.1rem;
    width: 100%;
  }

  @media ${devices.laptop} {
    height: 3rem;
    padding: 0.5rem 1rem;
    max-width: 13rem;
  }

  &:hover {
    background-color: black;
    color: white;
  }

  &:disabled {
    background-color: #5b5b5b;
    color: white;
    cursor: not-allowed;
  }
`;

export const StyledInput = styled.input`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 3rem;
  padding: 0.5rem;
  width: 100%;

  @media ${devices.mobileS} {
    padding: 0.25rem;
  }
`;

export const UtilityButton = styled.button`
  background-color: none;
  border: 1px solid ${colors.gray400};
  border-radius: 6px;
  color: ${colors.gray400};
  font-size: 0.7rem;
  padding: 0.5rem 1rem;
  text-transform: uppercase;

  @media ${devices.mobileS} {
    padding: 1.1rem;
    width: 100%;
  }

  @media ${devices.laptop} {
    padding: 0.5rem 1rem;
    height: 2.5rem;
    max-width: 11rem;
  }

  &:hover {
    background-color: ${colors.white};
    border: 1px solid ${colors.gray600};
    color: ${colors.gray600};
  }
`;
