import Loader from "../shared/Loader";
import { bytesToSize } from "../utils/helpers";
import PropTypes from "prop-types";
import React from "react";

import {
  FileContainer,
  FileDeleteIcon,
  FileDetails,
  FileDetailsDot,
  FileInfoColumn,
  FileName,
  FileTypeIcon,
  FileUploadHeader,
  LoaderContainer,
  UploadedFileZone,
  ImageFileIcon,
  PdfFileIcon,
  TrashIcon,
  FileUploadErrorContainer,
  FileUploadError,
} from "./styles";

const PolicyVerifyArea = ({
  attachmentError,
  handleDeleteFile,
  isSubmitting,
  uploadedFileMetadata: { name, size, typeInfo },
  uploadFileText,
}) => {
  return (
    <UploadedFileZone>
      <FileUploadHeader>{uploadFileText}</FileUploadHeader>
      <FileContainer>
        <FileTypeIcon
          aria-label={`${typeInfo} file icon`}
          data-testid={`${typeInfo.toLowerCase()}-file-icon`}
        >
          {typeInfo === "image" && (
            <ImageFileIcon />
          )}
          {typeInfo === "PDF" && (
            <PdfFileIcon />
          )}
        </FileTypeIcon>
        <FileInfoColumn>
          <FileName data-testid="file-name">{name}</FileName>
          <FileDetails>
            <div>{typeInfo}</div>
            <FileDetailsDot />
            <div>{bytesToSize(size)}</div>
          </FileDetails>
        </FileInfoColumn>
        {isSubmitting ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <FileDeleteIcon
            aria-label="remove file"
            data-testid="file-remove-icon"
            onClick={handleDeleteFile}
          >
            <TrashIcon />
          </FileDeleteIcon>
        )}
      </FileContainer>
      {attachmentError && (
        <FileUploadErrorContainer>
          <FileUploadError>
            {attachmentError}
          </FileUploadError>
        </FileUploadErrorContainer>
      )}
    </UploadedFileZone>
  );
};

PolicyVerifyArea.propTypes = {
  attachmentError: PropTypes.string,
  handleDeleteFile: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  uploadedFileMetadata: PropTypes.shape({
    name: PropTypes.string,
    typeInfo: PropTypes.string,
    size: PropTypes.number,
  }),
  uploadFileText: PropTypes.string.isRequired,
};

export default PolicyVerifyArea;
