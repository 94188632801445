import styled from "@emotion/styled";
import { devices } from '../../stylesheets/devices.js';
import { colors } from '../../stylesheets/colors.js';

const CardChevronContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.3rem;  
  font-weight: 400;
  height: 100%;
  justify-content: end;
  padding-right: 1.5rem;
`;

const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  width: 100%;
`;

const PropertyName = styled.h2`
  font-size: 1.1rem;  
  font-weight: 600;
  margin-bottom: 0.35rem;
`;

const TenuresCard = styled.button`
  text-align: left;
  background-color: ${colors.lightGray};
  border-radius: 10px;
  box-shadow: ${(props) => (props.isUnassignedUnit ? "none" : "0 0.35rem 1.1rem 0 rgba(142, 168, 180, 0.4)")};
  color: ${colors.almostBlack};
  cursor: pointer;
  cursor: ${(props) => (props.isUnassignedUnit ? "not-allowed" : "pointer")};
  display: flex;
  outline: 1px solid ${colors.lightGrayOutline};
  width: 100%;

  &:hover {
    background-color: ${(props) => (props.isUnassignedUnit ? colors.lightGray : colors.lightGrayHover)};
    outline: 1px solid ${(props) => (props.isUnassignedUnit ? colors.lightGrayOutline : "#c9c9c9")};
  }
`;

const TenuresContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 45rem;

  @media ${devices.mobileS} {
    width: 100%;
  }

  @media ${devices.tablet} {
    max-width: 75%;
  }
  
  @media ${devices.laptopL} {
    max-width: 65%;
  }
`;

const TenureInfo = styled.h2`
  color: ${(props) => (props.isUnassignedUnit ? colors.fileError : colors.almostBlack)};
  font-size: 1rem;  
  margin-bottom: 0.25rem;
`;

const OptInButton = styled.button`
  margin-left: auto;
  background-color: ${(props) => (props.isOptedIn ? '#D0271C' : colors.buttonSubmit)};
  border: none;
  border-radius: 8px;
  color: ${colors.gray25};
  font-size: 1rem;
  font-weight: 600;
  padding: 0.8rem 1.2rem;
  text-transform: uppercase;
  white-space: nowrap;
  &:hover {
    background-color: ${(props) => (props.isOptedIn ? '#9A1C1C' : colors.buttonSubmitHover)};
    color: ${colors.white};
  }
  &:disabled {
    background-color: ${colors.gray50};
    border: none;
    color: ${colors.gray300};
    cursor: not-allowed;
  }
`;

const OptInCard = styled.div`
  text-align: left;
  background-color: ${colors.lightGray};
  border-radius: 10px;
  color: ${colors.almostBlack};
  display: flex;
  outline: 1px solid ${colors.lightGrayOutline};
  width: 100%;
`;

const OptInContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 45rem;
  border-top: 1px solid ${colors.lightGrayOutline};
  padding-top: 1.5rem;
  margin-bottom: 2rem;

  @media ${devices.mobileS} {
    width: 100%;
  }

  @media ${devices.tablet} {
    max-width: 75%;
  }
  
  @media ${devices.laptopL} {
    max-width: 65%;
  }
`;

const OptInRightContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: end;
  padding-right: 1.5rem;
`;

export {
  CardChevronContainer,
  CardTextContainer,
  OptInButton,
  OptInCard,
  OptInContainer,
  OptInRightContent,
  PropertyName,
  TenuresCard,
  TenuresContainer,
  TenureInfo,
};