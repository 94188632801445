import queryString from "query-string";

window.stern = window.stern || {};

const appendQueryToUrl = (param, value) => {
  const { url, query } = queryString.parseUrl(window.location.href);

  let updatedQuery = { ...query };
  if (value === undefined || value === "") {
    updatedQuery[param] = undefined;
  } else {
    updatedQuery = { ...query, [param]: value };
  }

  document.location.href = queryString.stringifyUrl({
    url: url,
    query: updatedQuery
  });
};

window.stern.appendQueryToUrl = appendQueryToUrl;
