import React, { useContext } from "react";
import useCompanyImage from '../../hooks/useCompanyImage'

import CompanyContext from "../../contexts/CompanyContext";
import { CompanyLogo, PageContainer, ScrollContainer, TopNavBar, WindowContainer } from "../shared/styles";

const PortalWrapper = ({ children }) => {
  const { residentPortalLogoSlug } = useContext(CompanyContext);
  const { loading, error, image } = useCompanyImage(residentPortalLogoSlug)
  const alt = `${residentPortalLogoSlug} company logo`
  return (
    <WindowContainer>
      <TopNavBar>
        <CompanyLogo data-testid={`${residentPortalLogoSlug}-company-logo`} src={image} alt={alt} />
      </TopNavBar>
      <ScrollContainer>
        <PageContainer>
          {children}
        </PageContainer>
      </ScrollContainer>
    </WindowContainer>
  );
};

export default PortalWrapper;