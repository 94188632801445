import axios from "axios";
import { FILE_ERROR_MESSAGES, MAX_FILE_SIZE, SUPPORTED_MIME_TYPES } from "../utils/constants";
import { fileMetadata, redirectTo } from "../utils/helpers";
import PolicyVerifyArea from "./PolicyVerifyArea";
import React, { useRef, useState } from "react";

import {
  ButtonSubmit,
  FileAddInstructions,
  FileCriteriaContainer,
  FileDropZone,
  FileInput,
  FileUploadButtonsContainer,
  FileUploadContainer,
  FileUploadHeader,
  FileUploadTopBar,
  UploadIcon,
  FileUploadErrorContainer,
  FileUploadError,
  SectionHeader,
} from "./styles";

const PolicyUpload = ({ fileTypesAllowed, isCompanyPortal, tenureData, email }) => {
  const inputRef = useRef(null);

  const [attachmentError, setAttachmentError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileMetadata, setUploadedFileMetadata] = useState(null);

  const UPLOAD_HEADER = `Double check that your proof of coverage for renter's liability insurance does all of the following:`;
  const FILE_ADD_INSTRUCTIONS_TEXT = "Drag and drop or browse to add an image or PDF file";
  const SUBMIT_TEXT = "Submit";
  const UPLOAD_FILE_TEXT = "upload file";
  const GENERAL_UPLOAD_ERROR_TEXT = "Error uploading file";
  const SUBMIT_PATH = isCompanyPortal ? "/company_portal/policy" : "/resident_portal/policy";

  const renderVerificationList = () => {
    const verificationItems = Object.values(tenureData.policy_verifications).map((verification_message, idx) => {
      return <li key={idx}>- <span dangerouslySetInnerHTML={{ __html: verification_message }} /></li>
    });
    return (
      <ul>{verificationItems}</ul>
    )
  }

  const areButtonsDisabled = () => {
    return !uploadedFile || isSubmitting;
  };

  const emptyFileInput = () => {
    if (inputRef != null) {
      setTimeout(() => {
        inputRef.current.value = null;
      }, 500);
    }
  };

  const validateSelectedFile = async (file) => {
    if (!SUPPORTED_MIME_TYPES.includes(file.type)) {
      setAttachmentError(FILE_ERROR_MESSAGES.unsupportedMimeType);
      emptyFileInput();
      return false;
    }
    if (file.size > MAX_FILE_SIZE) {
      setAttachmentError(FILE_ERROR_MESSAGES.fileSize);
      emptyFileInput();
      return false;
    }
    return true;
  };

  const hideUploadFileValidationError = () => {
    setTimeout(() => {
      setAttachmentError(null);
    }, 6000);
  };

  const uploadFile = async (file) => {
    setAttachmentError(null);
    try {
      const isValid = await validateSelectedFile(file);
      if (!isValid) {
        hideUploadFileValidationError();
        return;
      }
    } catch (error) {
      console.error(error);
    }

    const metadata = fileMetadata(file);
    setUploadedFile(file);
    setUploadedFileMetadata(metadata);
  };

  const handleDeleteFile = () => {
    setAttachmentError(null);
    setUploadedFile(null);
    emptyFileInput();
  };

  const handleOnDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleOnDrop = (event) => {
    event.preventDefault();
    uploadFile(event.dataTransfer.files[0], inputRef);
  };

  const submitRequest = () => {
    const url = SUBMIT_PATH;
    const formData = new FormData();
    formData.append("file", uploadedFile || "");
    formData.append("tenure_key", tenureData.tenure_key);
    formData.append("email", email);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "X-CSRF-TOKEN": document.querySelector("meta[name=csrf-token]").content,
      },
    };
    return axios.post(url, formData, config);
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      setIsSubmitting(true);
      setAttachmentError(null);
      const response = await submitRequest();
      if (response.data.success) {
        redirectTo(response.data.redirect_url);
      } else {
        setIsSubmitting(false);
        setAttachmentError(GENERAL_UPLOAD_ERROR_TEXT);
      }
    } catch (error) {
      setIsSubmitting(false);
      const server_error_msg = error.response.data.errors[0];
      setAttachmentError(server_error_msg || GENERAL_UPLOAD_ERROR_TEXT);
    }
  };

  return (
    <FileUploadContainer>
      <FileUploadTopBar>
        <SectionHeader style={{textAlign: "center"}}>{UPLOAD_HEADER}</SectionHeader>
        <FileCriteriaContainer>
          {renderVerificationList()}
        </FileCriteriaContainer>
      </FileUploadTopBar>
      {uploadedFile && uploadedFileMetadata ? (
        <PolicyVerifyArea
          attachmentError={attachmentError}
          handleDeleteFile={handleDeleteFile}
          isSubmitting={isSubmitting}
          uploadedFileMetadata={uploadedFileMetadata}
          uploadFileText={UPLOAD_FILE_TEXT}
        />
      ) : (
        <FileDropZone
          aria-label="file upload drop zone"
          data-testid="file-drop-zone"
          onDragOver={handleOnDragOver}
          onDrop={handleOnDrop}
        >
          <FileInput
            aria-label="file upload input"
            accept={fileTypesAllowed.join(",")}
            data-testid="file-input"
            id="upload"
            onChange={(event) => {
              uploadFile(event.target.files[0], inputRef);
            }}
            ref={inputRef}
            type="file"
          />
          <FileUploadHeader>{UPLOAD_FILE_TEXT}</FileUploadHeader>
          <UploadIcon />
          <FileAddInstructions>
            {FILE_ADD_INSTRUCTIONS_TEXT}
          </FileAddInstructions>
          {attachmentError && (
            <FileUploadErrorContainer>
              <FileUploadError data-testid="file-upload-error">
                {attachmentError}
              </FileUploadError>
            </FileUploadErrorContainer>
          )}
        </FileDropZone>
      )}
      <FileUploadButtonsContainer>
        <ButtonSubmit
          data-testid="file-upload-submit-btn"
          disabled={areButtonsDisabled()}
          onClick={handleSubmit}
        >
          {SUBMIT_TEXT}
        </ButtonSubmit>
      </FileUploadButtonsContainer>
    </FileUploadContainer>
  );
};

export default PolicyUpload;
