import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { isEmpty } from "../utils/helpers";

import {
  EmailInputContainer,
  EmailInputHeader,
  EmailInputSubHeader,
  InputAndButtonContainer,
  StyledButton,
  StyledEmailInput,
} from "./styles";

const EmailInput = ({ isCompanyPortal, isResidentLookup, recaptchaSiteKey }) => {
  const BUTTON_TEXT = isResidentLookup ? "find resident" : "find my account";
  const BUTTON_SUBMIT_TEXT = "finding...";
  const HEADER_TEXT = isResidentLookup ? "Resident lookup" : "Let's get started.";
  const SUBHEADER_TEXT = isCompanyPortal ?
    `Please enter the email associated with ${isResidentLookup ? "a resident lease" : "your company user account"}:` :
    "Please enter the email associated with your lease:";
  const SUBMIT_PATH = isCompanyPortal ?
    (isResidentLookup ? "/company_portal/lookup/resident_email_submit" : "/company_portal/lookup/email_submit") :
    "/resident_portal/lookup/email_submit";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isVerifiedPerson, setIsVerifiedPerson] = useState(recaptchaSiteKey ? false : true);

  const handleKeyDown = (event) => {
    if (isSubmitting) return;
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit(event);
    }
  };

  const handleSubmit = async () => {
    const email = inputValue;

    try {
      setIsSubmitting(true);
      const response = await axios.get(SUBMIT_PATH, { params: { email: email } });
      window.location = response.data.redirect_url;
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  };

  const isSubmitDisabled = isEmpty(inputValue) || !isVerifiedPerson || isSubmitting;

  return (
    <EmailInputContainer isResidentLookup={isResidentLookup}>
      <EmailInputHeader>{HEADER_TEXT}</EmailInputHeader>
      <EmailInputSubHeader>{SUBHEADER_TEXT}</EmailInputSubHeader>
      <InputAndButtonContainer>
        <StyledEmailInput
          aria-label="email input"
          autoFocus
          data-testid="email-input"
          disabled={isSubmitting}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
          placeholder='your@email.com'
          type='email'
        />
      </InputAndButtonContainer>
      {recaptchaSiteKey &&(
        <InputAndButtonContainer>
          <ReCAPTCHA
            data-testid="recaptcha"
            sitekey={recaptchaSiteKey}
            onChange={() => setIsVerifiedPerson(true)}
          />
        </InputAndButtonContainer>
      )}
      <InputAndButtonContainer>
        <StyledButton
          data-testid="email-submit-btn"
          disabled={isSubmitDisabled}
          onClick={() => handleSubmit()}
        >
          {isSubmitting ? BUTTON_SUBMIT_TEXT : BUTTON_TEXT}
        </StyledButton>
      </InputAndButtonContainer>
    </EmailInputContainer>
  );
}

export default EmailInput;
