import React, { useState } from "react";
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";


import PortalWrapper from "../PortalWrapper";
import { StandardText } from "../shared/styles";
import { CompanyProvider } from "../../contexts/CompanyContext";
import {
  CardChevronContainer,
  CardTextContainer,
  OptInButton,
  OptInCard,
  OptInContainer,
  OptInRightContent,
  PropertyName,
  TenuresCard,
  TenuresContainer,
  TenureInfo,
} from "./styles";

library.add(faChevronRight);

const ResidentSelectUnit = ({ companyGroupSlug, isCompanyPortal = false, residentPortalLogoSlug, email, hasOptInFeature, optInDate, activeResidentTenures }) => {
  const HEADER_TEXT = "With which unit would you like to proceed?"
  const FOOTER_TEXT = "If you don't see the unit you're looking for, please make sure you're using the email address associated with your resident portal login."
  const SUBMIT_PATH = isCompanyPortal ? "/company_portal/lookup/unit_submit" : "/resident_portal/lookup/unit_submit";
  const TOGGLE_OPT_IN_PATH = "/resident_portal/lookup/opt_in_toggle";
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSelectTenure = async (tenureKey) => {
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const response = await axios.get(SUBMIT_PATH, { params: { tenure_key: tenureKey, email: email } });
      window.location = response.data.redirect_url;
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  }
  
  const handleToggleOptIn = async () => {
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const response = await axios.get(TOGGLE_OPT_IN_PATH, { params: { email: email } });
      window.location = response.data.redirect_url;
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  }

  const Tenures = ({ tenures }) => {
    return tenures.map((tenure) => {
      const isUnassignedUnit = tenure.is_unassigned_unit;
      const unassignedMessage = `You must be assigned to a unit in order to upload a policy. Please reach out to the ${tenure.property_name} property manager for help with this.`
      return (
        <TenuresCard isUnassignedUnit={isUnassignedUnit} data-testid="tenure-card" disabled={isUnassignedUnit || isSubmitting} key={tenure.tenure_key} onClick={() => handleSelectTenure(tenure.tenure_key)}>
          <CardTextContainer>
            <PropertyName data-testid="tenure-resident-and-property-name">{`${tenure.resident_name} – ${tenure.property_name}`}</PropertyName>
            <TenureInfo data-testid="tenure-address" isUnassignedUnit={isUnassignedUnit}>
              <span><b>Address: </b></span>
              {tenure.unit_address}
            </TenureInfo>
            <TenureInfo data-testid="tenure-status">
              <span><b>Status: </b></span>
              {tenure.tenure_status}
            </TenureInfo>
            <TenureInfo data-testid="tenure-resident-type">
              <span><b>Resident Type: </b></span>
              {tenure.resident_type}
            </TenureInfo>
            <TenureInfo data-testid="tenure-move-in-date">
              <span><b>Move-In Date: </b></span>
              {tenure.movein_date}
            </TenureInfo>
            {isUnassignedUnit && <TenureInfo isUnassignedUnit={isUnassignedUnit} data-testid="tenure-unassigned-message">{unassignedMessage}</TenureInfo>}
          </CardTextContainer>
          {!isUnassignedUnit && (
            <CardChevronContainer>
              <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
            </CardChevronContainer>
          )}
        </TenuresCard>
      );
    });
  }

  return (
    <CompanyProvider companyGroupSlug={companyGroupSlug} residentPortalLogoSlug={residentPortalLogoSlug} >
      <PortalWrapper>
        <StandardText>{HEADER_TEXT}</StandardText>
          <TenuresContainer>
            <Tenures tenures={activeResidentTenures} />
          </TenuresContainer>
        {!isCompanyPortal && (<StandardText>{FOOTER_TEXT}</StandardText>)}
        {hasOptInFeature && (
          <>
            <OptInContainer>
              <StandardText>{'If you prefer to be enrolled in the Master Resident Liability Program, you can opt-in below:'}</StandardText>
              <OptInCard>
                <CardTextContainer>
                  {optInDate ? (
                    `Opted-in as of ${optInDate}`
                  ) : (
                    ""
                  )}
                </CardTextContainer>
                <OptInRightContent>
                  {optInDate ? (
                    <OptInButton isOptedIn={!!optInDate} disabled={isSubmitting} onClick={() => handleToggleOptIn()}>
                      Remove
                    </OptInButton>
                  ) : (
                    <OptInButton isOptedIn={!!optInDate} disabled={isSubmitting} onClick={() => handleToggleOptIn()}>
                      Opt-In
                    </OptInButton>
                  )}
                  
                </OptInRightContent>
              </OptInCard>
            </OptInContainer>
          </>
        )}
      </PortalWrapper>
    </CompanyProvider>
  );
};

export default ResidentSelectUnit;