import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { isEmpty } from "../utils/helpers";

import {
  InputContainer,
  InputSubHeader,
  InputAndButtonContainer,
  StyledButton,
  StyledInput,
} from "./styles";

const EmailInput = ({ recaptchaSiteKey, handleEmailSubmit }) => {
  const BUTTON_TEXT = "continue";
  const UNFOUND_EMAIL_TEXT = "Unable to find an account with that email. Please try again.";
  const EMAIL_SUBMIT_PATH = "/users/email_submit";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isEmailUnfound, setIsEmailUnfound] = useState(false);
  const [isVerifiedPerson, setIsVerifiedPerson] = useState(recaptchaSiteKey ? false : true);

  const handleKeyDown = (event) => {
    if (isSubmitting) return;
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit(event);
    }
  };

  const handleSubmit = async () => {
    setIsEmailUnfound(false);
    setIsSubmitting(true);
    const email = inputValue;

    const config = {
      headers: {
        "X-CSRF-TOKEN": document.querySelector("meta[name=csrf-token]").content,
      },
    };

    const params = {
      user: {
        email: email,
      }
    }

    try {
      const response = await axios.post(EMAIL_SUBMIT_PATH, params, config);
      const loginType = response.data.login_type;
      handleEmailSubmit(email, loginType)
    } catch (error) {
      setIsEmailUnfound(true);
      console.error('Sign-in error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isSubmitDisabled = isEmpty(inputValue) || !isVerifiedPerson || isSubmitting;

  return (
    <InputContainer>
      {isEmailUnfound && (
        <InputSubHeader>{UNFOUND_EMAIL_TEXT}</InputSubHeader>
      )}
      <InputAndButtonContainer>
        <StyledInput
          aria-label="email input"
          autoFocus
          data-testid="email-input"
          disabled={isSubmitting}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
          placeholder='your@email.com'
          type='email'
        />
      </InputAndButtonContainer>
      {recaptchaSiteKey &&(
        <InputAndButtonContainer>
          <ReCAPTCHA
            data-testid="recaptcha"
            sitekey={recaptchaSiteKey}
            onChange={() => setIsVerifiedPerson(true)}
          />
        </InputAndButtonContainer>
      )}
      <InputAndButtonContainer>
        <StyledButton
          data-testid="email-submit-btn"
          disabled={isSubmitDisabled}
          onClick={() => handleSubmit()}
        >
          {BUTTON_TEXT}
        </StyledButton>
      </InputAndButtonContainer>
    </InputContainer>
  );
}

export default EmailInput;
