import styled from "@emotion/styled";
import { devices } from '../../stylesheets/devices.js';
import { colors } from '../../stylesheets/colors.js';

const CompanyLogo = styled.img`
  display: block;
  max-width: 40rem;
  max-height: 4.5rem;
  width: auto;
  height: auto;
`;

const PageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;
  height: calc(100vh - 5rem);
  justify-content: flex-start;
  margin: 0 auto 5rem auto;
  padding: 2.5rem 0;

  @media ${devices.mobileS} {
    max-width: 90vw;
  }

  @media ${devices.tablet} {
    max-width: 80vw;
  }
`;

const ScrollContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;  
  justify-content: center;
  overflow: auto;
`;

const StandardText = styled.div`
  color: ${colors.almostBlack};
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 400;
  gap: 1rem;
`;

const TopNavBar = styled.div`
  background-color: ${colors.white};
  box-shadow: 0 4px 6px -6px ${colors.gray500};
  margin-bottom: 0.6rem;
  height: 5.5rem;
  width: 100%;
  padding: 0.5rem;
`;

const WindowContainer = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
`;

export {
  CompanyLogo,
  PageContainer,
  ScrollContainer,
  StandardText,
  TopNavBar,
  WindowContainer
};