import React from "react";
import PropTypes from "prop-types";

import PortalWrapper from "../PortalWrapper";
import Found from "./Found";
import NotFound from "./NotFound";
import { CompanyProvider } from "../../contexts/CompanyContext";

const ResidentLookup = ({
  companyGroupSlug,
  isCompanyPortal = false,
  isResidentLookup = false,
  residentPortalLogoSlug,
  found = false,
  email
}) => {
  return (
    <CompanyProvider companyGroupSlug={companyGroupSlug} residentPortalLogoSlug={residentPortalLogoSlug} >
      <PortalWrapper>
        {found ? (
          <Found email={email} isCompanyPortal={isCompanyPortal} />
        ) : (
          <NotFound
            companyGroupSlug={companyGroupSlug}
            isCompanyPortal={isCompanyPortal}
            isResidentLookup={isResidentLookup}
          />
        )}
      </PortalWrapper>
    </CompanyProvider>
  );
};

ResidentLookup.propTypes = {
  found: PropTypes.bool
};

export default ResidentLookup;