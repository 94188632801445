import styled from "@emotion/styled/macro";
import { devices } from '../../stylesheets/devices.js';
import { colors } from '../../stylesheets/colors.js';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCloudArrowUp, faFileImage, faFilePdf, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from '@mui/material';

library.add(faCloudArrowUp);

const ButtonSubmit = styled.button`
  margin-left: auto;
  background-color: ${colors.buttonSubmit};
  border: none;
  border-radius: 8px;
  color: ${colors.gray25};
  font-size: 1rem;
  font-weight: 600;
  padding: 0.8rem 1.2rem;
  text-transform: uppercase;
  &:hover {
    background-color: ${colors.buttonSubmitHover};
    color: ${colors.white};
  }
  &:disabled {
    background-color: ${colors.gray50};
    border: none;
    color: ${colors.gray300};
    cursor: not-allowed;
  }
`;

const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
`;

const DownloadButton = styled(Button)`
  border-radius: 8px;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  padding: 0.2rem 0.5rem;
  text-transform: none;
`;

const FileAddInstructions = styled.span`
  color: ${colors.gray600};
  display: inline;
  font-size: 0.9rem;
  font-weight: 400;
  text-align: center;
  max-width: 40rem;
  width: 100%;
  ${FileDropZone}:hover & {
    color: ${colors.gray700};
  }
`;

const FileContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 3rem;
`;

const FileCriteriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  margin-top: 1rem;

  @media ${devices.mobileS} {
    gap: 0.5rem;
  }

  @media ${devices.tablet} {
    gap: 0.1rem;
  }
`;

const FileDeleteIcon = styled.div`
  color: ${colors.gray500};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2rem;
  &:hover {
    color: ${colors.gray600};
  }
`;

const FileDetails = styled.div`
  align-items: center;
  color: ${colors.gray300};
  display: flex;
  font-size: 0.9rem;
  font-weight: 400;
  gap: 1rem;
`;

const FileDetailsDot = styled.div`
  background-color: ${colors.gray100};
  border-radius: 0.05rem;
  height: 0.3rem;
  width: 0.3rem;
`;

const FileDropZone = styled.label`
  align-items: center;
  background-color: ${colors.gray25};
  border: none;
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  for: "upload";
  gap: 1.3rem;
  justify-content: flex-start;
  padding: 2rem;
  width: 100%;
  &:hover {
    background-color: ${colors.fileZoneHover};
  }
`;

const FileInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const FileInput = styled.input`
  display: none;
`;

const FileName = styled.div`
  color: ${colors.gray700};
  font-size: 1rem;
  font-weight: 600;
`;

const FileTypeIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 6rem;
`;

const FileUploadButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 3rem;
`;
  
const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;

  @media ${devices.mobileS} {
    padding: 0 0.5rem;
  }

  @media ${devices.tablet} {
    padding: 0 2rem;
  }
`;

const FileUploadError = styled.div`
  border-radius: 6px;
  color: ${colors.fileError};
  flex-shrink: 0;
  font-weight: 400;
  font-size: 1rem;
`;

const FileUploadErrorContainer = styled.div`
  display: flex;
  height: 1rem;
  justify-content: center;
  width: 100%;
`;

const FileUploadHeader = styled.div`
  color: ${colors.gray500};
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  ${FileDropZone}:hover & {
    color: ${colors.gray600};
  }
`;

const FileUploadTopBar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const LoaderContainer = styled.div`
  margin: 0 -6.25rem;
  overflow: hidden;
`;

const PolicyInfo = styled.div`
  font-size: 0.9rem;  
  margin-bottom: 0.5rem;
`;

const DeniedReasonList = styled.ul`
  margin-left: 1rem;
`;

const PolicySubmissionsCard = styled.div`
  text-align: left;
  background-color: ${colors.white};
  border-radius: 10px;
  box-shadow: 0 0.35rem 1.1rem 0 rgba(142, 168, 180, 0.4);
  color: ${colors.almostBlack};
  display: flex;
  flex-direction: column;
  outline: 1px solid ${colors.lightGrayOutline};
  width: 100%;
`;

const PolicySubmissionDate = styled.div`
  font-size: 1.2rem;  
  font-weight: 600;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${colors.lightGrayHover};
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 3rem;
  width: 45rem;

  @media ${devices.mobileS} {
    width: 100%;
  }

  @media ${devices.tablet} {
    max-width: 75%;
  }
  
  @media ${devices.laptopL} {
    max-width: 65%;
  }
`;

const SectionHeader = styled.div`
  color: ${colors.almostBlack};
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  font-weight: 500;
  gap: 1rem;
`;

const ImageFileIcon = () => {
  return <StyledImageFileIcon icon={faFileImage} />;
};

const PdfFileIcon = () => {
  return <StyledPdfFileIcon icon={faFilePdf} />;
};

const TrashIcon = () => {
  return <StyledTrashIcon icon={faTrash} />;
};

const UploadIcon = () => {
  return <StyledUploadIcon icon={faCloudArrowUp} />;
};

const StyledImageFileIcon = styled(FontAwesomeIcon)`
  color: ${colors.fileImageIcon};
  font-size: 4rem;
`;

const StyledPdfFileIcon = styled(FontAwesomeIcon)`
  color: ${colors.filePdfIcon};
  font-size: 4rem;
`;

const StyledTrashIcon = styled(FontAwesomeIcon)`
  color: ${colors.gray300};
  font-size: 2rem;
  &:hover {
    color: ${colors.gray500};
  };
`;

const StyledUploadIcon = styled(FontAwesomeIcon)`
  color: ${colors.gray400};
  font-size: 5rem;
  ${FileDropZone}:hover & {
    color: ${colors.fileUploadIconHover};
  }
`;

const UploadedFileZone = styled.div`
  align-items: center;
  background-color: white;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: flex-start;
  padding: 3rem 2rem 0rem;
  width: 100%;
`;

export {
  CardTextContainer,
  ButtonSubmit,
  DeniedReasonList,
  DownloadButton,
  FileAddInstructions,
  FileContainer,
  FileCriteriaContainer,
  FileDeleteIcon,
  FileDetails,
  FileDetailsDot,
  FileDropZone,
  FileInfoColumn,
  FileName,
  FileInput,
  FileTypeIcon,
  FileUploadButtonsContainer,
  FileUploadContainer,
  FileUploadError,
  FileUploadErrorContainer,
  FileUploadHeader,
  FileUploadTopBar,
  LoaderContainer,
  PolicyInfo,
  PolicySubmissionDate,
  PolicySubmissionsCard,
  SectionContainer,
  SectionHeader,
  UploadedFileZone,
  ImageFileIcon,
  PdfFileIcon,
  TrashIcon,
  UploadIcon,
};