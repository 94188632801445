import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import PolicyUpload from "./PolicyUpload";
import { CompanyProvider } from "../../contexts/CompanyContext";
import { Chip } from '@mui/material';
import { capitalize } from "lodash";

import PortalWrapper from "../PortalWrapper";
import {
  CardTextContainer,
  DeniedReasonList,
  SectionContainer,
  SectionHeader,
  PolicyInfo,
  PolicySubmissionsCard,
  PolicySubmissionDate,
  DownloadButton
} from "./styles";

library.add(faChevronRight);

const ResidentPolicyShow = ({ companyGroupSlug, isCompanyPortal = false, residentPortalLogoSlug, tenureData, email }) => {
  const PROOF_OF_SUBMISSIONS_HEADER = "Policy Submissions"

  const STATUS_COLORS = {
    "pending": "info",
    "approved": "success",
    "denied": "error",
    "canceled": "warning",
  }

  const renderStatusChip = (status) => {
    return <Chip label={capitalize(status)} color={STATUS_COLORS[status]} size="small" />
  };

  const renderDeniedReasons = (policy) => {
    const list_items = policy.denied_verification_messages.map((message, idx) =>
      <li key={idx}>{`${idx + 1}. `}<span dangerouslySetInnerHTML={{ __html: message }} /></li>
    );
    return (
      <DeniedReasonList>{list_items}</DeniedReasonList>
    )
  };

  const PolicySubmissions = () => {
    return tenureData.policies.map((policy) => {
      return (
        <PolicySubmissionsCard key={policy.id}>
          <CardTextContainer>
            <PolicySubmissionDate data-testid={`policy-submission-date-${policy.id}`}>
              {`Submission on ${policy.upload_date}`}
            </PolicySubmissionDate>
            <PolicyInfo data-testid={`policy-approval-status-${policy.id}`}>
              <span><b>Approval Status: </b></span>
              {renderStatusChip(policy.approval_status)}
            </PolicyInfo>
            {policy.approval_status === "denied" && (
              <PolicyInfo>
                <span><b>Missing or Invalid Verification(s): </b></span>
                {renderDeniedReasons(policy)}
              </PolicyInfo>
            )}
            <PolicyInfo data-testid={`policy-start-date-${policy.id}`}>
              <span><b>Start Date: </b></span>
              {policy.start_date}
            </PolicyInfo>
            <PolicyInfo data-testid={`policy-end-date-${policy.id}`}>
              <span><b>End Date: </b></span>
              {policy.end_date}
            </PolicyInfo>
            {policy.document_filename && (
              <PolicyInfo data-testid={`policy-attachments-${policy.id}`}>
                <span><b>Attachments: </b></span>
                <a href={policy.document_download_path} target="_blank">
                  {policy.document_filename}
                  <DownloadButton data-testid={`policy-attachments-download-btn-${policy.id}`} variant="outlined" size="small">
                    Download
                  </DownloadButton>
                </a>
              </PolicyInfo>
            )}
          </CardTextContainer>
        </PolicySubmissionsCard>
      );
    });
  }

  return (
    <CompanyProvider companyGroupSlug={companyGroupSlug} residentPortalLogoSlug={residentPortalLogoSlug} >
      <PortalWrapper>
        {tenureData.policies.length > 0 && (
          <>
            <SectionHeader>{PROOF_OF_SUBMISSIONS_HEADER}</SectionHeader>
            <SectionContainer>
              <PolicySubmissions />
            </SectionContainer>
            <SectionHeader>Upload Additional Proof of Coverage</SectionHeader>
          </>
        )}
        <SectionContainer>
          <PolicyUpload
            fileTypesAllowed={tenureData.policy_document_file_types_allowed}
            isCompanyPortal={isCompanyPortal}
            tenureData={tenureData}
            email={email}
          />
        </SectionContainer>
      </PortalWrapper>
    </CompanyProvider>
  );
};

export default ResidentPolicyShow;