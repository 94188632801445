import styled from "@emotion/styled";
import { devices } from '../../stylesheets/devices.js';
import { colors } from '../../stylesheets/colors.js';

const InputAndButtonContainer = styled.h2`
  display: flex;
  gap: 1.5rem;
  width: 100%;

  @media ${devices.mobileS} {
    flex-direction: column;
  }
  
  @media ${devices.laptop} {
    flex-direction: row;
  }
`;

const PasscodeInputContainer = styled.div`
  background-color: ${colors.periwinkle};
  border-radius: 10px;
  box-shadow: 0 0.6rem 1.8rem 0 rgba(142, 168, 180, 0.5);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 2.5rem;
  width: 45rem;

  @media ${devices.mobileS} {
    width: 100%;
  }

  @media ${devices.tablet} {
    max-width: 80%;
  }
  
  @media ${devices.laptopL} {
    max-width: 70%;
  }
`;


const PasscodeInputHeader = styled.h1`
  font-weight: 600;
  margin-bottom: 1rem;

  @media ${devices.mobileS} {
    font-size: 1.4rem;
  }

  @media ${devices.tablet} {
    font-size: 1.6rem;
  }
`;

const PasscodeInputSubHeader = styled.h2`
  font-size: 1rem;  
  font-weight: 500;
  margin-bottom: 1rem;
`;

const StyledBackButton = styled.button`
  background-color: ${colors.almostBlack};
  border-radius: 6px;
  color: #f7f7f7;
  font-weight: 500;
  padding: 0.5rem 1rem;
  text-transform: uppercase;

  @media ${devices.mobileS} {
    padding: 1.1rem;
    width: 100%;
  }

  @media ${devices.laptop} {
    height: 3rem;
    padding: 0.5rem 1rem;
    max-width: 10rem;
  }

  &:hover {
    background-color: black;
    color: white;
  }

  &:disabled {
    background-color: #5b5b5b;
    color: white;
  }
`;

const StyledButton = styled.button`
  background-color: ${colors.almostBlack};
  border-radius: 6px;
  color: #f7f7f7;
  font-weight: 500;
  padding: 0.5rem 1rem;
  text-transform: uppercase;

  @media ${devices.mobileS} {
    padding: 1.1rem;
    width: 100%;
  }

  @media ${devices.laptop} {
    height: 3rem;
    padding: 0.5rem 1rem;
    max-width: 13rem;
  }

  &:hover {
    background-color: black;
    color: white;
  }

  &:disabled {
    background-color: #5b5b5b;
    color: white;
  }
`;

const StyledPasscodeInput = styled.input`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 3rem;
  padding: 0.5rem;
  width: 100%;

  @media ${devices.mobileS} {
    padding: 0.25rem;
  }
`;

const ValidationMessage = styled.div`
  color: darkred;
  font-size: 1rem;
  margin-top: 0.5rem;
  text-align: center;
`;

export {
  InputAndButtonContainer,
  PasscodeInputContainer,
  PasscodeInputHeader,
  PasscodeInputSubHeader,
  StyledBackButton,
  StyledButton,
  StyledPasscodeInput,
  ValidationMessage
};