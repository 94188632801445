export const colors = {
  almostBlack: '#202020',
  almostBlackDisabled: '#656565',
  lightBlue: '#d2ecfb',
  lightGray: '#f0f0f0',
  lightGrayHover: '#dcdcdc',
  lightGrayOutline: '#e6e6e6',
  scionYellow: '#FFCC3A',
  white: '#ffffff',
  periwinkle: '#C3CDE6',
  lightGreen: '#CDE6C3',

  black: '#000000',
  gray900: '#141414',
  gray800: '#292929',
  gray700: '#3d3d3d',
  gray600: '#525252',
  gray500: '#666666',
  gray400: '#7a7a7a',
  gray300: '#8f8f8f',
  gray200: '#a3a3a3',
  gray100: '#b8b8b8',
  gray75: '#cccccc',
  gray50: '#e0e0e0',
  gray25: '#eaeaea',

  fileZoneHover: '#dfe5e8',
  fileUploadIconHover: '#132f42',
  fileError: 'red',
  filePdfIcon: '#c43950',
  fileImageIcon: '#562c9e',

  buttonSubmit: '#1b779e',
  buttonSubmitHover: '#12668a',
}