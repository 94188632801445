import React, { useState } from "react";
import axios from "axios";
import { isEmpty } from "../utils/helpers";

import {
  InputContainer,
  InputHeader,
  InputSubHeader,
  InputAndButtonContainer,
  StyledButton,
  StyledInput,
} from "./styles";

const PasswordInput = ({ email, loginState }) => {
  const BUTTON_TEXT = "log in";
  const PASSCODE_HEADER_TEXT = `Please check your email`;
  const PASSCODE_SUBHEADER_TEXT = `You will be receiving a 6-digit passcode shortly. Once entered correctly below, you will have access to the platform for 8 hours. Reauthentication will be required after this time period expires.`;
  const INVALID_CREDENTIALS_TEXT = `Invalid credentials. Please try again.`;
  const SUBMIT_PATH = "/users/sign_in";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInvalidCredsText, setShowInvalidCredsText] = useState(false);
  const [invalidLoginAttempts, setInvalidLoginAttempts] = useState(0);
  const [passwordValue, setPasswordValue] = useState("");

  const handleKeyDown = (event) => {
    if (isSubmitting) return;
    if (event.key === "Enter" && !event.shiftKey) {
      handleSignIn(event);
    }
  };

  const handleSignIn = async () => {
    setIsSubmitting(true);
    setShowInvalidCredsText(false);

    const config = {
      headers: {
        "X-CSRF-TOKEN": document.querySelector("meta[name=csrf-token]").content,
      },
    };

    const params = {
      user: {
        email: email,
        password: passwordValue,
      }
    }

    try {
      const response = await axios.post(SUBMIT_PATH, params, config);
      window.location = response.data.redirect_url;
    } catch (error) {
      console.error('Sign-in error:', error);
      handleLoginFailure()
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLoginFailure = () => {
    if (invalidLoginAttempts + 1 <= 2) { // 3 attempts
      setShowInvalidCredsText(true);
      setInvalidLoginAttempts(invalidLoginAttempts + 1)
    } else {
      window.location = "/admin/login";
    }
  }

  const isSubmitDisabled = isEmpty(passwordValue) || isSubmitting;

  return (
    <InputContainer>
      { loginState === "passcode" && (
        <>
          <InputHeader>{PASSCODE_HEADER_TEXT}</InputHeader>
          <InputSubHeader>{PASSCODE_SUBHEADER_TEXT}</InputSubHeader>
        </>
      )}
      <InputAndButtonContainer>
        <StyledInput
          aria-label="password input"
          autoFocus
          data-testid="password-input"
          disabled={isSubmitting}
          onChange={(e) => setPasswordValue(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
          placeholder={loginState === 'passcode' ? 'passcode' : 'password'}
          type='password'
        />
      </InputAndButtonContainer>
      <InputAndButtonContainer>
        <StyledButton
          data-testid="password-submit-btn"
          disabled={isSubmitDisabled}
          onClick={() => handleSignIn()}
        >
          {BUTTON_TEXT}
        </StyledButton>
      </InputAndButtonContainer>
      {showInvalidCredsText && (
        <InputSubHeader>{INVALID_CREDENTIALS_TEXT}</InputSubHeader>
      )}
    </InputContainer>
  );
}

export default PasswordInput;
